<template>
  <div>
    <top-menu></top-menu>
    <section id="container" style="height: 100%;">
      <left-menu></left-menu>
      <section id="content">
        <section class="audiobox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box" style="z-index:999;position:relative;">
            <h2>Study Room</h2>
          </div>
          <div class="audio_container">
            <div class="audio_close_btn clickable"  @click.prevent="$router.replace({ name: 'study-room.content', params: { talkScheduleId: talkScheduleId } })">
              <a href="#"><img src="/image/sub/study_close_btn.png"/></a>
            </div>
            <div class="audio_player">
              <audio controls="controls" class="audio_controls" id="player">
                <source :src="file" type="audio/mpeg"/>
              </audio>
              <div class="custom_controls">
                <a class="play" title="Play"></a>
                <a class="pause" title="Pause"></a>
                <div class="time_slider"></div>
                <div class="timer">00:00</div>
                <div class="volume">
                  <a class="mute" title="Mute"></a>
                  <a class="unmute" title="Unmute"></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer-navigation :back="{ name: 'study-room.content', params: { talkScheduleId: talkScheduleId } }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import StudyRoom from '@/models/StudyRoom'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  name: 'StudyRoom.Content.Type.Audio',
  data () {
    return {
      loading: true,
      study_room: {},
      file: null,
      content: {},
      pagination: {},
      next: null,
      back: null,
      totalPage: 0,
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      studyRoomId: typeof this.$route.params.studyRoomId !== 'undefined' ? this.$route.params.studyRoomId : '',
      page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
    }
  },
  mounted () {
    this.loadContent(this.page, this.talkScheduleId, this.studyRoomId)
  },
  methods: {
    async loadContent (page, talkScheduleId, studyRoomId) {
      this.loading = true
      const searchParams = {}
      searchParams.page = page
      this.$router.replace({ query: searchParams })

      const response = await StudyRoom.viewContent({ page: page, talkScheduleId: talkScheduleId, studyRoomId: studyRoomId })
      this.content = response.data.data[0]
      this.file = response.data.data[0].content.file
      $('#player').src = this.file // eslint-disable-line
      $('#player').load() // eslint-disable-line
      this.pagination = response.data.meta.pagination
      this.next = this.pagination.next
      this.back = this.pagination.back
      this.study_room = response.data.meta.study_room
      $('.audio_player').myPlayer(); // eslint-disable-line
      this.loading = false

      setTimeout(() => {
        if (this.content.is_done !== 1) {
          this.updateContentStatus(this.talkScheduleId, this.studyRoomId, this.content.id)
        }
      }, 5000)
    },
    updateContentStatus (talkScheduleId, studyRoomId, contentId) {
      StudyRoom.contentIsDone({ talkScheduleId: talkScheduleId, studyRoomId: studyRoomId, contentId: contentId })
    }
  }
}

/* eslint-disable */

function rectime(secs) {
    var hr = Math.floor(secs / 3600);
    var min = Math.floor((secs - (hr * 3600))/60);
    var sec = Math.floor(secs - (hr * 3600) - (min * 60));

    if (hr < 10) {hr = '0' + hr; }
    if (min < 10) {min = '0' + min;}
    if (sec < 10) {sec = '0' + sec;}
    if (hr) {hr = '00';}
    return hr + ':' + min + ':' + sec;
}

(function($) {
    $.fn.myPlayer = function() {
        return this.each(function() {
            // variables
            var $oMain = $(this);
            var $oAudio = $('audio', $oMain);
            var $oPlay = $('.play', $oMain);
            var $oPause = $('.pause', $oMain);
            var $oTimeSlider = $('.time_slider', $oMain);
            var $oTimer = $('.timer', $oMain);
            var $oVolSlider = $('.volume_slider', $oMain);
            var $oMute = $('.mute', $oMain);
            var $oUnmute = $('.unmute', $oMain);
            var bTimeSlide = false;
            var iVolume = 1;

            // functions section
            var prepareTimeSlider = function() {
                if (! $oAudio[0].readyState) {
                    setTimeout(prepareTimeSlider, 1000);
                } else {
                    $oTimeSlider.slider({
                        value: 0,
                        step: 0.01,
                        orientation: 'horizontal',
                        range: 'min',
                        max: $oAudio[0].duration,
                        animate: true,
                        slide: function() {
                            bTimeSlide = true;
                        },
                        stop:function(e, ui) {
                            bTimeSlide = false;
                            $oAudio[0].currentTime = ui.value;
                        }
                    });
                };
            };

            // events section
            $oPlay.click(function () {  
                $oAudio[0].play();
                $oPlay.hide();
                $oPause.css('display', 'block');
            });
            $oPause.click(function () {  
                $oAudio[0].pause();
                $oPause.hide();
                $oPlay.css('display', 'block');
            });
            $oMute.click(function () {  
                $oAudio[0].muted = true;
                $oVolSlider.slider('value', '0');
                $oMute.hide();
                $oUnmute.css('display', 'block');
            });
            $oUnmute.click(function () {  
                $oAudio[0].muted = false;
                $oVolSlider.slider('value', iVolume);
                $oUnmute.hide();
                $oMute.css('display', 'block');
            });

            // bind extra inner events
            $oAudio.bind('ended', function() {
                $oAudio[0].pause();
                $oPause.hide();
                $oPlay.css('display', 'block');
            });
            $oAudio.bind('timeupdate', function() {
                var iNow = $oAudio[0].currentTime;
                $oTimer.text(rectime(iNow));
                if (! bTimeSlide)
                    $oTimeSlider.slider('value', iNow);
            });

            // rest initialization
            $oVolSlider.slider({
                value: 1,
                orientation: 'vertical',
                range: 'min',
                max: 1,
                step: 0.02,
                animate: true,
                slide: function(e, ui) {
                    $oAudio[0].muted = false;
                    iVolume = ui.value;
                    $oAudio[0].volume = ui.value;
                }
            });
            prepareTimeSlider();
            $oAudio.removeAttr('controls');
        });
    };
})(jQuery);

/* eslint-enable */

</script>

<style scoped>

</style>
